import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all sub
export const getAllFileExport = createAsyncThunk('fileExport/getAllFileExport', async (main, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllFileExport`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create sub
export const createFileExport = createAsyncThunk('fileExport/createFileExport', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createFileExport`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Update sub
export const updateFileExport = createAsyncThunk('fileExport/updateFileExport', async ({ id, data }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData(`/api/admin/updateFileExport/${id}`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete sub
export const deleteFileExport = createAsyncThunk('fileExport/deleteFileExport', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteFileExport/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
