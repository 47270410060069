import baseURL from './Api';
import getCsrfToken from './Csrf';
// import { getDataLocalStorage } from '../utils/helpers/localStorageHelper';

export const postDataWithImage = async (url, params) => {
  await getCsrfToken();
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const res = await baseURL.post(url, params, config);
  return res.data;
};

export const postData = async (url, params) => {
  await getCsrfToken();
  const res = await baseURL.post(url, params);
  return res.data;
};

// export const postDataWithImage = async (url, params) => {
//   await getCsrfToken();
//   const config = {
//     headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.post(url, params, config);
//   return res.data;
// };

// export const postData = async (url, params) => {
//   await getCsrfToken();
//   const config = {
//     headers: { Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.post(url, params, config);
//   return res.data;
// };
