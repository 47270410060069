import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all sub
export const getAllSub = createAsyncThunk('sub/getAllSub', async (main, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllSubs`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get sub by Main by admin
export const getSubByMainByAdmin = createAsyncThunk('sub/getSubByMainByAdmin', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getSubByMainByAdmin`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get sub by Main
export const getSubByMainId = createAsyncThunk('sub/getSubByMainId', async (mainId, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getSubByMainId/${mainId}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get sub by Main
export const getSubByMain = createAsyncThunk('sub/getSubByMain', async ({ mainId, subId }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getSubByMain/${mainId}/${subId}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create sub
export const createSub = createAsyncThunk('sub/createSub', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createSub`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Update sub
export const updateSub = createAsyncThunk('sub/updateSub', async ({ id, data }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData(`/api/admin/updateSub/${id}`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete sub
export const deleteSub = createAsyncThunk('sub/deleteSub', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteSub/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
