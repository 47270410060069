import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  isOpenDelete: false,
  componentName: null,
  fullWidth: true,
  item: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.componentName = action.payload.name;
      state.item = action.payload?.item || null;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.componentName = null;
      state.item = null;
    },
    openModalDelete: (state, action) => {
      state.isOpenDelete = true;
      state.componentName = action.payload;
    },
    closeModalDelete: (state) => {
      state.isOpenDelete = false;
    },
  },
});
export const { openModal, closeModal, openModalDelete, closeModalDelete } = modalSlice.actions;
export default modalSlice.reducer;
