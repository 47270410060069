import { createAsyncThunk } from '@reduxjs/toolkit';
import { putData } from '../../../api/putData';
import getData from '../../../api/getData';
import { postData } from '../../../api/postData';
// import deleteData from '../../../api/deleteData';

// // login admin
export const loginUser = createAsyncThunk('authAdmin/loginUser', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData('api/login', formData);
    // console.log('🚀 ~ file: authAdminAction.js ~ line 25 ~ loginAdmin ~ res', res);
    return res;
  } catch (error) {
    // console.log('🚀 ~ file: authAdminAction.js ~ line 23 ~ loginAdmin ~ error.response.data', error.response.data);
    return rejectWithValue(error.response.data);
  }
});
// login admin
// export const loginUser = createAsyncThunk('authAdmin/loginUser', async (formData, thunkAPI) => {
//   const { rejectWithValue } = thunkAPI;
//   try {
//     const res = await postData('api/user/access-tokens', formData);
//     console.log('🚀 ~ file: authAdminAction.js ~ line 25 ~ loginAdmin ~ res', res);
//     return res;
//   } catch (error) {
//     console.log('🚀 ~ file: authAdminAction.js ~ line 23 ~ loginAdmin ~ error.response.data', error.response.data);
//     return rejectWithValue(error.response.data);
//   }
// });

// // logout admin
export const logoutUser = createAsyncThunk('authUser/logoutUser', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData('api/logout', formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// logout admin
// export const logoutUser = createAsyncThunk('authUser/logoutUser', async (formData, thunkAPI) => {
//   const { rejectWithValue } = thunkAPI;
//   try {
//     const res = await deleteData('api/user/access-tokens', formData);
//     return res;
//   } catch (error) {
//     return rejectWithValue(error.response.data);
//   }
// });

// update password admin
export const updatePasswordUser = createAsyncThunk('authUser/updatePasswordUser', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData('api/user/password', formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Logged admin
export const getLoggedUser = createAsyncThunk('authUser/getLoggedUser', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData('/api/getMe');
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
