import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { FileExportSchema } from '../../../../utils/validation';
// actions
import { createFileExport } from '../../../../redux/actions/admin/fileExportAction';
import { closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';

const FileExportCreate = () => {
  // state
  const { loading } = useSelector((state) => state.filesExport);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(FileExportSchema),
    defaultValues: {
      name: '',
    },
  });

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // functions
  const onSubmit = async (data) => {
    await dispatch(createFileExport(data))
      .unwrap()
      .then(() => {
        dispatch(openAlert());
        dispatch(closeModal());
        reset();
      })
      .catch((errors) => {
        if (errors.errors) {
          Object.keys(errors.errors).forEach((error) => {
            setError(error, {
              type: error,
              message: errors.errors[error][0],
            });
          });
        } else {
          dispatch(closeModal());
          dispatch(openAlert('error'));
        }
      });
  };

  return (
    <>
      <DialogContent>
        <Stack sx={{ my: 2 }}>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                type={'name'}
                onChange={onChange}
                value={value}
                error={!!errors.name}
                label={t('Name File Export')}
                helperText={errors.name?.message}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
        <LoadingButton
          color="success"
          loading={loading}
          loadingIndicator={t('Loading…')}
          onClick={handleSubmit(onSubmit)}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FileExportCreate;
