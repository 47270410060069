import baseURL from './Api';
import getCsrfToken from './Csrf';
// import { getDataLocalStorage } from '../utils/helpers/localStorageHelper';

export const putDataWithImage = async (url, params) => {
  await getCsrfToken();
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const res = await baseURL.put(url, params, config);
  return res.data;
};

export const putData = async (url, params) => {
  // await getCsrfToken();
  const res = await baseURL.put(url, params);
  return res.data;
};
// export const putDataWithImage = async (url, params) => {
//   const config = {
//     headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.put(url, params, config);
//   return res.data;
// };

// export const putData = async (url, params) => {
//   const config = {
//     headers: { Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.put(url, params, config);
//   return res.data;
// };
