import { createSlice } from '@reduxjs/toolkit';
import { createAdmin, deleteAdmin, getAllAdmin, updateAdmin } from '../../actions/admin/adminAction';

const initialState = {
  admins: [],
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  extraReducers: {
    // get All admins
    [getAllAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = action.payload;
    },
    [getAllAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create admin
    [createAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins.push(action.payload);
    },
    [createAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // update admin
    [updateAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = state.admins.filter((el) => el.id !== action.payload?.id);
      state.admins.push(action.payload);
    },
    [updateAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // delete admins
    [deleteAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.admins = state.admins.filter((el) => el.id !== action.payload);
    },
    [deleteAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default adminSlice.reducer;
