import { createSlice } from '@reduxjs/toolkit';
import {
  createFileReceived,
  deleteFileReceived,
  getAllFileReceived,
  updateFileReceived,
} from '../../actions/admin/fileReceivedAction';

const initialState = {
  filesReceived: [],
  loading: false,
  error: null,
};

const fileReceivedSlice = createSlice({
  name: 'filesReceived',
  initialState,
  extraReducers: {
    // get All Files Received
    [getAllFileReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllFileReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesReceived = action.payload;
    },
    [getAllFileReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create File Received
    [createFileReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createFileReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesReceived.push(action.payload);
    },
    [createFileReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // update File Received
    [updateFileReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateFileReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesReceived = state.filesReceived.filter((el) => el.id !== action.payload?.id);
      state.filesReceived.push(action.payload);
    },
    [updateFileReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // delete File Received
    [deleteFileReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteFileReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesReceived = state.filesReceived.filter((el) => el.id !== action.payload);
    },
    [deleteFileReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default fileReceivedSlice.reducer;
