import { createAsyncThunk } from '@reduxjs/toolkit';
import getData from '../../../api/getData';

// get  notifications admin
export const getNotifications = createAsyncThunk('adminNotifications/getNotifications', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/adminNotifications`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
