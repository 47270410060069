import { createAsyncThunk } from '@reduxjs/toolkit';
import getData from '../../../api/getData';

// get all export by sub
export const getAllExport = createAsyncThunk('report/getAllExport', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/getAllExport${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get all Received by export sub id
export const getAllReceivedByExport = createAsyncThunk('report/getAllReceivedByExport', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/getAllReceivedByExport${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get all received by sub
export const getAllReceived = createAsyncThunk('report/getAllReceived', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/getAllReceived${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
