import PropTypes from 'prop-types';
// mui
import { Autocomplete, TextField } from '@mui/material';
// react hook form
import { Controller } from 'react-hook-form';

ControlledAutoCompleteInput.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  getOptionLabel: PropTypes.any,
  control: PropTypes.any,
  defaultValue: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
  loading: PropTypes.bool,
};

function ControlledAutoCompleteInput({ options = [], label, control, defaultValue, name, errors, loading }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          multiple
          size="small"
          freeSolo
          loading={loading}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name] && errors[name].message}
            />
          )}
          {...field}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
}

export default ControlledAutoCompleteInput;
