import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { FileExportSchema } from '../../../../utils/validation';
// actions
import { closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { updateFileExport } from '../../../../redux/actions/admin/fileExportAction';

const FileExportEdit = () => {
  // state
  const { item } = useSelector((state) => state.modal);
  // console.log('🚀 ~ file: SubAdministrationEdit.js ~ line 24 ~ AdminsManagementEdit ~ item', item);
  const { loading } = useSelector((state) => state.filesExport);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(FileExportSchema),
    defaultValues: {
      name: '',
    },
  });

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // functions
  const onSubmit = async (data) => {
    await dispatch(updateFileExport({ id: item.id, data }))
      .unwrap()
      .then(async () => {
        dispatch(openAlert());
        dispatch(closeModal());
        reset();
      })
      .catch((errors) => {
        if (errors.errors) {
          Object.keys(errors.errors).forEach((error) => {
            setError(error, {
              type: error,
              message: errors.errors[error][0],
            });
          });
        } else {
          dispatch(closeModal());
          dispatch(openAlert('error'));
        }
      });
  };

  // useEffect
  useEffect(() => {
    reset({
      name: item.name,
    });
  }, [item, reset]);

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid xs={12} md={12}>
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'text'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  label={t('Name File Export')}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
        <LoadingButton
          color="success"
          loading={loading}
          loadingIndicator={t('Loading…')}
          onClick={handleSubmit(onSubmit)}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FileExportEdit;
