import { createSlice } from '@reduxjs/toolkit';
import { getNotifications } from '../../actions/admin/notificationAction';

const initialState = {
  count: null,
  loading: false,
  error: null,
};

const statisticsSlice = createSlice({
  name: 'adminNotifications',
  initialState,
  extraReducers: {
    // get admin Notifications
    [getNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.count = action.payload;
    },
    [getNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default statisticsSlice.reducer;
