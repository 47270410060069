import { createSlice } from '@reduxjs/toolkit';
import { getNotifications, markAsReadAll } from '../../actions/user/notificationAction';

const initialState = {
  notifications: [],
  count: null,
  loading: false,
  error: null,
};

const statisticsSlice = createSlice({
  name: 'userNotifications',
  initialState,
  extraReducers: {
    // get user Notifications
    [getNotifications.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications = action.payload?.notifications;
      state.count = action.payload?.count;
    },
    [getNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // markAsReadAll user Notifications
    [markAsReadAll.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [markAsReadAll.fulfilled]: (state, action) => {
      state.loading = false;
      state.notifications = action.payload?.notifications;
      state.count = action.payload?.count;
    },
    [markAsReadAll.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default statisticsSlice.reducer;
