import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../components/Loadable';

// layouts
const SimpleLayout = Loadable(lazy(() => import('../layouts/simple')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const SimpleRoutes = {
  element: <SimpleLayout />,
  children: [
    { element: <Navigate to="user/dashboard" />, index: true },
    { path: '404', element: <Page404 /> },
    { path: '*', element: <Navigate to="/404" /> },
  ],
};

export const NotFound = {
  path: '*',
  element: <Navigate to="/404" replace />,
};
