import { useRoutes } from 'react-router-dom';

// routes
// admin
import { AdminRoutes, AuthenticationAdminRoutes, SuperAdminRoutes } from './AdminRoutes';
// user
import { UserRoutes, AuthenticationUserRoutes } from './UserRoutes';
// simple
import { NotFound, SimpleRoutes } from './SimpleRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AdminRoutes,
    SuperAdminRoutes,
    AuthenticationAdminRoutes,
    UserRoutes,
    AuthenticationUserRoutes,
    SimpleRoutes,
    NotFound,
  ]);
}
