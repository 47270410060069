import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

// hook form
import { useForm } from 'react-hook-form';
// actions
import {
  exportMainEditStatus,
  getAllReceivedByExport,
  getImagesExport,
} from '../../../../redux/actions/admin/exportAction';
// utils
import { fDate } from '../../../../utils/formatTime';
// components
import { ControlledDatePicker } from '../../../../components/form-Input';
import { MaterialReact, Actions, Status } from '../../../../components/material-table';
// slices
import { openModal, closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';

const InternalReceivingReport = () => {
  // i18next
  const { t } = useTranslation();
  // state
  const { AllReceivedByExports, loading } = useSelector((state) => state.exports);
  // console.log('🚀 ~ file: ExportReport.js ~ line 8 ~ ExportReport ~ exports', AllReceivedByExports);

  // dispatch
  const dispatch = useDispatch();

  const {
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      dateFrom: Date.now(),
      dateTo: Date.now(),
    },
  });

  // watch
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');

  // functions
  const editStatus = useCallback(
    async (status, exportId) => {
      // console.log('🚀 ~ file: InternalReceivingEdit.js ~ line 50 ~ onSubmit ~ data', data);
      await dispatch(
        exportMainEditStatus({
          status,
          export_id: exportId,
        })
      )
        .unwrap()
        .then(async () => {
          dispatch(openAlert());
          dispatch(closeModal());
        });
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'received_serial_number',
        header: t('serial_number'),
      },
      {
        accessorKey: 'status',
        header: t('Action Status'),
        enableColumnFilter: false,
        Cell: (prop) => {
          const { row } = prop;
          return (
            <Status
              onClickEdit={() => editStatus(row.original.status ? null : true, row.original.id)}
              data={row.original}
            />
          );
        },
      },
      {
        accessorKey: 'export_received_at',
        header: t('Date'),
      },
      {
        accessorKey: 'serial_number',
        header: t('referrer_number'),
      },
      {
        accessorKey: 'main_name',
        header: t('sender'),
      },
      {
        accessorKey: 'sud_name',
        header: t('subSender'),
      },
      {
        accessorKey: 'subject',
        header: t('subject'),
        size: 300,
      },
      {
        accessorKey: 'sub_name_r',
        header: t('subRecipient'),
      },
      {
        accessorKey: 'note',
        header: t('notes'),
      },
      {
        accessorKey: 'admin.name',
        header: t('User Name'),
      },
    ],
    [t, editStatus]
  );

  // useEffect

  useEffect(() => {
    (async () => {
      if (dateFrom && dateTo) {
        await dispatch(getAllReceivedByExport(`?dateFrom=${fDate(dateFrom)}&dateTo=${fDate(dateTo)}`));
      }
    })();
  }, [dispatch, dateFrom, dateTo]);

  return (
    <Box sx={{ height: 500, width: '100%', direction: 'ltr' }}>
      <Grid sx={{ mb: 2 }} container spacing={2}>
        <Grid item xs={12} md={4}>
          <ControlledDatePicker
            control={control}
            name="dateFrom"
            label={t('Date From')}
            errors={errors}
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledDatePicker
            control={control}
            name="dateTo"
            label={t('Date To')}
            errors={errors}
            defaultValue={''}
          />
        </Grid>
      </Grid>

      <MaterialReact
        columns={columns}
        data={AllReceivedByExports?.data || []}
        state={{ isLoading: loading }}
        renderRowActions={(row) => {
          const getImages = async () => {
            dispatch(openModal({ name: 'ViewImages' }));
            await dispatch(getImagesExport(row.row.original.id));
          };
          const internalReceivingEdit = async () => {
            dispatch(openModal({ name: 'InternalReceivingEdit', item: row.row.original }));
          };
          return <Actions onClick={getImages} onClickEdit={row.row.original.edit ? internalReceivingEdit : null} />;
        }}
      />
    </Box>
  );
};

export default InternalReceivingReport;
