import { createSlice } from '@reduxjs/toolkit';
import { getLoggedAdmin, loginAdmin, logoutAdmin, updatePasswordAdmin } from '../../actions/admin/authAdminAction';

const initialState = {
  authSuperAdmin: null,
  authAdmin: null,
  loading: false,
  error: null,
};

const authAdminSlice = createSlice({
  name: 'authAdmin',
  initialState,
  reducers: {
    setAuthAdmin: (state, action) => {
      state.authAdmin = action.payload;
    },
    clearAuthAdmin: (state) => {
      state.authAdmin = null;
    },
    setAuthSuperAdmin: (state, action) => {
      state.authSuperAdmin = action.payload;
    },
    clearAuthSuperAdmin: (state) => {
      state.authSuperAdmin = null;
    },
  },
  extraReducers: {
    // login Admin
    [loginAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginAdmin.fulfilled]: (state) => {
      state.loading = false;
      //  state.authAdmin = action.payload;
    },
    [loginAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // logout Admin
    [logoutAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [logoutAdmin.fulfilled]: (state) => {
      state.loading = false;
      state.authAdmin = null;
    },
    [logoutAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // update password Admin
    [updatePasswordAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updatePasswordAdmin.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePasswordAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get Logged admin
    [getLoggedAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLoggedAdmin.fulfilled]: (state) => {
      state.loading = false;
      //  state.authAdmin = action.payload;
    },
    [getLoggedAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setAuthAdmin, clearAuthAdmin, setAuthSuperAdmin, clearAuthSuperAdmin } = authAdminSlice.actions;

export default authAdminSlice.reducer;
