import { configureStore } from '@reduxjs/toolkit';
// admin
import statistics from './slices/admin/statisticsSlice';
import adminNotifications from './slices/admin/notificationSlice';
import authAdmin from './slices/admin/authAdminSlice';
import admins from './slices/admin/adminSlice';
import users from './slices/admin/userSlice';
import exports from './slices/admin/exportSlice';
import receivers from './slices/admin/receivedSlice';
import mains from './slices/admin/mainsSlice';
import subs from './slices/admin/subSlice';
import filesExport from './slices/admin/fileExportSlice';
import filesReceived from './slices/admin/fileReceivedSlice';
import scan from './slices/admin/scanSlice';
import modal from './slices/modal';
import alert from './slices/alert';
import lightbox from './slices/lightboxSlice';
import crop from './slices/admin/cropSlice';
// user
import userStatistics from './slices/user/statisticsSlice';
import authUser from './slices/user/authUserSlice';
import reportUser from './slices/user/reportSlice';
import userNotifications from './slices/user/notificationSlice';

export default configureStore({
  reducer: {
    statistics,
    userStatistics,
    adminNotifications,
    userNotifications,
    authAdmin,
    authUser,
    reportUser,
    admins,
    users,
    exports,
    receivers,
    mains,
    subs,
    filesExport,
    filesReceived,
    scan,
    modal,
    alert,
    lightbox,
    crop,
  },
  devTools: false,
});
