import { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeAlert } from '../../redux/slices/alert';

const CustomizedSnackbars = () => {
  const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

  // state
  const { isOpen, severity, vertical, horizontal } = useSelector((state) => state.alert);
  // dispatch
  const dispatch = useDispatch();
  // i18next
  const { t } = useTranslation();
  // functions

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeAlert());
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', color: 'white' }}>
          {severity === 'success' ? t('The action ran successfully!') : t('The action not ran')}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomizedSnackbars;
