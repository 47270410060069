import baseURL from './Api';
// import { getDataLocalStorage } from '../utils/helpers/localStorageHelper';
// import getCsrfToken from './Csrf';

const deleteData = async (url, params) => {
  // await getCsrfToken();
  const res = await baseURL.delete(url, params);
  return res.data;
};

// const deleteData = async (url) => {
//   const config = {
//     headers: { Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.delete(url, config);
//   return res.data;
// };

export default deleteData;
