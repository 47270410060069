import { createSlice } from '@reduxjs/toolkit';
import { createReceived, getAllReceived } from '../../actions/admin/receivedAction';

const initialState = {
  receivers: [],
  received: [],
  loading: false,
  error: null,
};

const receivedSlice = createSlice({
  name: 'received',
  initialState,
  extraReducers: {
    // get All receivers
    [getAllReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.receivers = action.payload;
    },
    [getAllReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create received
    [createReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.received.push(action.payload);
    },
    [createReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default receivedSlice.reducer;
