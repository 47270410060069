import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// mui
import Box from '@mui/material/Box';
// components
import AlertDialogDelete from '../../../../components/utils/AlertDialogDelete';
import { MaterialReact, Actions } from '../../../../components/material-table';
// actions
import { closeModalDelete, openModal, openModalDelete } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { deleteFileExport, getAllFileExport } from '../../../../redux/actions/admin/fileExportAction';

const FileExportList = () => {
  // state
  const { filesExport, loading } = useSelector((state) => state.filesExport);
  const [id, setId] = useState(0);

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name File Export'),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    [t]
  );

  // functions
  const deleteMa = useCallback(
    (id) => {
      setId(id);
      dispatch(openModalDelete());
    },
    [dispatch]
  );

  const handleDelete = useCallback(async () => {
    await dispatch(deleteFileExport(id))
      .unwrap()
      .then(() => {
        dispatch(openAlert());
        dispatch(closeModalDelete(id));
      })
      .catch(() => {
        dispatch(closeModalDelete());
        dispatch(openAlert('error'));
        // console.log('🚀 ~ file: MainAdministrationPage.js ~ line 46 ~ handleDelete ~ errors', errors);
      });
  }, [dispatch, id]);

  // useEffect
  useEffect(() => {
    (async () => {
      await dispatch(getAllFileExport());
    })();
  }, [dispatch]);

  return (
    <>
      <Box sx={{ height: 500, width: '100%', direction: 'ltr' }}>
        <MaterialReact
          columns={columns}
          data={filesExport ?? []}
          state={{ isLoading: loading }}
          positionActionsColumn="last"
          enableRowNumbers
          renderRowActions={(row) => (
            <Actions
              onClickEdit={() => dispatch(openModal({ name: 'FileExportEdit', item: row.row.original }))}
              onClickDelete={() => deleteMa(row.row.original.id)}
            />
          )}
        />
      </Box>
      <AlertDialogDelete handleDelete={handleDelete} />
    </>
  );
};

export default FileExportList;
