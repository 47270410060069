import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// material
import Box from '@mui/material/Box';
// actions
import { getReceivedByExport, getImagesExport } from '../../../../redux/actions/admin/exportAction';
// components
import { MaterialReact, Actions } from '../../../../components/material-table';
// slices
import { openModal } from '../../../../redux/slices/modal';

const InternalReceivingManagement = () => {
  // i18next
  const { t } = useTranslation();
  // state
  const { receivedByExports, loading } = useSelector((state) => state.exports);
  // console.log('🚀 ~ file: ExportReport.js ~ line 8 ~ receivedByExports', receivedByExports);

  // dispatch
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'serial_number',
        header: t('referrer_number'),
      },
      {
        accessorKey: 'export_at',
        header: t('Date'),
      },
      {
        accessorKey: 'main_name',
        header: t('sender'),
      },
      {
        accessorKey: 'subject',
        header: t('subject'),
        size: 300,
      },
      {
        accessorKey: 'note',
        header: t('notes'),
      },
    ],
    [t]
  );
  // useEffect
  useEffect(() => {
    (async () => {
      await dispatch(getReceivedByExport());
    })();
  }, [dispatch]);

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <MaterialReact
        columns={columns}
        data={receivedByExports?.data || []}
        state={{ isLoading: loading }}
        renderRowActions={(row) => {
          const getImages = async () => {
            await dispatch(getImagesExport(row.row.original.id));
            dispatch(openModal({ name: 'ViewImages' }));
          };
          const internalReceivingEdit = async () => {
            dispatch(openModal({ name: 'InternalReceivingAdd', item: row.row.original }));
          };
          return <Actions onClick={getImages} onClickEdit={internalReceivingEdit} />;
        }}
      />
    </Box>
  );
};

export default InternalReceivingManagement;
