import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../components/Loadable';

// layout user
const DashboardLayout = Loadable(lazy(() => import('../layouts/dashboard/user')));
// layout user guest
const GuestLayout = Loadable(lazy(() => import('../layouts/guest/user')));
// user pages
const DashboardUserPage = Loadable(lazy(() => import('../pages/user/DashboardUserPage')));
const ExportReportPage = Loadable(lazy(() => import('../pages/user/ExportReportPage')));
const InternalReceivingReportPage = Loadable(lazy(() => import('../pages/user/InternalReceivingReportPage')));
const ReceivedReportPage = Loadable(lazy(() => import('../pages/user/ReceivedReportPage')));

// auth user
const LoginPage = Loadable(lazy(() => import('../pages/auth/user/LoginPage')));
// ==============================|| ADMIN ||============================== //

export const UserRoutes = {
  path: 'user',
  element: <DashboardLayout name="user" path="user" />,
  children: [
    { element: <Navigate to="dashboard" />, index: true },
    { path: 'dashboard', element: <DashboardUserPage /> },
    { path: 'export', element: <ExportReportPage /> },
    { path: 'internal-receiving', element: <InternalReceivingReportPage /> },
    { path: 'received', element: <ReceivedReportPage /> },
  ],
};

export const AuthenticationUserRoutes = {
  path: 'user',
  element: <GuestLayout />,
  children: [{ path: 'login', element: <LoginPage /> }],
};
