import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// mui
import Box from '@mui/material/Box';
// components
import AlertDialogDelete from '../../../../components/utils/AlertDialogDelete';
import { MaterialReact, Actions } from '../../../../components/material-table';
// actions
import { closeModalDelete, openModalDelete } from '../../../../redux/slices/modal';
import { deleteMain } from '../../../../redux/actions/admin/mainAction';
import { openAlert } from '../../../../redux/slices/alert';

const MainAdministrationList = () => {
  // state
  const { mains, loading } = useSelector((state) => state.mains);
  const [id, setId] = useState(0);

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name Main Administrations'),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    [t]
  );
  // functions
  const deleteMa = useCallback(
    (id) => {
      setId(id);
      dispatch(openModalDelete());
    },
    [dispatch]
  );

  const handleDelete = useCallback(async () => {
    await dispatch(deleteMain(id))
      .unwrap()
      .then(() => {
        dispatch(openAlert());
        dispatch(closeModalDelete(id));
      })
      .catch(() => {
        dispatch(closeModalDelete());
        dispatch(openAlert('error'));
        // console.log('🚀 ~ file: MainAdministrationPage.js ~ line 46 ~ handleDelete ~ errors', errors);
      });
  }, [dispatch, id]);

  return (
    <>
      <Box sx={{ height: 500, width: '100%', direction: 'ltr' }}>
        <MaterialReact
          columns={columns}
          data={mains ?? []}
          state={{ isLoading: loading }}
          enableRowNumbers
          positionActionsColumn="last"
          renderRowActions={(row) => <Actions onClickDelete={() => deleteMa(row.row.original.id)} />}
        />
      </Box>
      <AlertDialogDelete handleDelete={handleDelete} />
    </>
  );
};

export default MainAdministrationList;
