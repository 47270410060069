import PropTypes from 'prop-types';
// mui
import { Autocomplete, TextField } from '@mui/material';
// react hook form
import { Controller } from 'react-hook-form';

ControlledAutoCompleteMultiple.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  getOptionLabel: PropTypes.any,
  control: PropTypes.any,
  defaultValue: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
  loading: PropTypes.bool,
};

function ControlledAutoCompleteMultiple({
  options = [],
  label,
  getOptionLabel,
  control,
  defaultValue,
  name,
  errors,
  loading,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          multiple
          size="small"
          freeSolo
          loading={loading}
          options={options}
          getOptionLabel={getOptionLabel}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name] && errors[name].message}
            />
          )}
          {...field}
          isOptionEqualToValue={(option, value) => value === undefined || value === '' || option.id === value.id}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
}

export default ControlledAutoCompleteMultiple;
