import { createSlice } from '@reduxjs/toolkit';
import {
  createSub,
  deleteSub,
  getAllSub,
  getSubByMain,
  getSubByMainByAdmin,
  getSubByMainId,
  updateSub,
} from '../../actions/admin/subAction';

const initialState = {
  subs: [],
  subByMainId: [],
  subByMainByAdmin: [],
  subsByMain: [],
  loading: false,
  error: null,
};

const subSlice = createSlice({
  name: 'sub',
  initialState,
  extraReducers: {
    // get All subs
    [getAllSub.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllSub.fulfilled]: (state, action) => {
      state.loading = false;
      state.subs = action.payload;
    },
    [getAllSub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get Sub By Main id
    [getSubByMainId.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSubByMainId.fulfilled]: (state, action) => {
      state.loading = false;
      state.subByMainId = action.payload;
    },
    [getSubByMainId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get Sub By Main by admin
    [getSubByMainByAdmin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSubByMainByAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.subByMainByAdmin = action.payload;
    },
    [getSubByMainByAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get Sub By Main
    [getSubByMain.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSubByMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.subsByMain = action.payload;
    },
    [getSubByMain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create sub
    [createSub.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createSub.fulfilled]: (state, action) => {
      state.loading = false;
      state.subs.push(action.payload);
    },
    [createSub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // update sub
    [updateSub.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateSub.fulfilled]: (state, action) => {
      state.loading = false;
      state.subs = state.subs.filter((el) => el.id !== action.payload?.id);
      state.subs.push(action.payload);
    },
    [updateSub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // delete sub
    [deleteSub.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteSub.fulfilled]: (state, action) => {
      state.loading = false;
      state.subs = state.subs.filter((el) => el.id !== action.payload);
    },
    [deleteSub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default subSlice.reducer;
