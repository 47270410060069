import { createAsyncThunk } from '@reduxjs/toolkit';
import getData from '../../../api/getData';

// get all statistics user
export const getStatistics = createAsyncThunk('statistic/statistics', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/statistics`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// get all  Chart user
export const getChart = createAsyncThunk('statistic/getChart', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/chart`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
