import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// mui
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const Status = ({ onClickEdit, data }) => {
  // console.log('🚀 ~ file: Status.js:10 ~ Status ~ data', data);
  // i18next
  const { t } = useTranslation();
  return (
    <Box>
      {onClickEdit && (
        <Tooltip sx={{ ml: 2 }} title={data.status ? t('receipt') : t('He did not arrive yet')}>
          <IconButton onClick={onClickEdit}>
            {data.status ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
Status.propTypes = {
  data: PropTypes.object,
  onClickEdit: PropTypes.func,
};

export default Status;
