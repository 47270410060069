import PropTypes from 'prop-types';
// mui
import TextField from '@mui/material/TextField';
// react hook form
import { Controller } from 'react-hook-form';

ControlledTextFieldMultiple.propTypes = {
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
  type: PropTypes.string,
  rows: PropTypes.number,
};

function ControlledTextFieldMultiple({ label, control, defaultValue, name, errors, type = 'text', rows }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          size="small"
          multiline
          rows={rows}
          fullWidth
          type={type}
          onChange={onChange}
          value={value}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
        />
      )}
    />
  );
}

export default ControlledTextFieldMultiple;
