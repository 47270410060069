// mui
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getAllMain } from '../../../../redux/actions/admin/mainAction';
import { getAllUser } from '../../../../redux/actions/admin/userAction';

const UserManagementSearch = () => {
  // state
  const { mains } = useSelector((state) => state.mains);
  const { user } = useSelector((state) => state.users);
  const [mainId, setMainId] = useState('');
  // console.log('🚀 ~ file: AdminsManagementSearch.js ~ line 13 ~ AdminsManagementSearch ~ mainId', mainId);

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // useEffect
  useEffect(() => {
    (async () => {
      await dispatch(getAllMain());
    })();
  }, [dispatch]);

  // useEffect
  useEffect(() => {
    (async () => {
      await dispatch(getAllUser(mainId));
    })();
  }, [mainId, dispatch, user]);

  return (
    <>
      <TextField
        id="standard-select-currency"
        select
        label={t('Name Main Administrations')}
        value={mainId}
        onChange={(e) => setMainId(e.target.value)}
        variant="standard"
      >
        <MenuItem value="">
          <em>{t('None')}</em>
        </MenuItem>
        {mains && mains.length > 0
          ? mains.map((main) => (
              <MenuItem key={main.id} value={main.id}>
                {main.name}
              </MenuItem>
            ))
          : null}
      </TextField>
    </>
  );
};

export default UserManagementSearch;
