import { createSlice } from '@reduxjs/toolkit';
import {
  createFileExport,
  deleteFileExport,
  getAllFileExport,
  updateFileExport,
} from '../../actions/admin/fileExportAction';

const initialState = {
  filesExport: [],
  loading: false,
  error: null,
};

const fileExportSlice = createSlice({
  name: 'fileExport',
  initialState,
  extraReducers: {
    // get All Files Export
    [getAllFileExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllFileExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesExport = action.payload;
    },
    [getAllFileExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create File Export
    [createFileExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createFileExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesExport.push(action.payload);
    },
    [createFileExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // update File Export
    [updateFileExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateFileExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesExport = state.filesExport.filter((el) => el.id !== action.payload?.id);
      state.filesExport.push(action.payload);
    },
    [updateFileExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // delete File Export
    [deleteFileExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteFileExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.filesExport = state.filesExport.filter((el) => el.id !== action.payload);
    },
    [deleteFileExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default fileExportSlice.reducer;
