import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
// mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Image from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CircularProgress from '@mui/material/CircularProgress';
// slices
import { closeModal } from '../../redux/slices/modal';
// components
import ComponentToPrint from '../print';

const ViewImages = () => {
  // state
  const { images, loading } = useSelector((state) => state.scan);
  // Ref
  const componentRef = useRef();
  // dispatch
  const dispatch = useDispatch();
  // i18next
  const { t } = useTranslation();

  return (
    <>
      <DialogContent sx={{ height: '100vh' }}>
        {!loading ? (
          <Stack>
            <Image cols={1}>
              {images.length > 0 &&
                images.map((image, index) => (
                  <ImageListItem
                    key={index}
                    sx={{
                      height: '100%',
                      width: '100%',
                      transition: 'opacity',
                      cursor: 'pointer',
                      '&:hover': { opacity: 0.8 },
                    }}
                  >
                    <img
                      style={{ height: '100%', width: '100%' }}
                      src={image}
                      srcSet={image}
                      alt={'images'}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
            </Image>
          </Stack>
        ) : (
          <Stack sx={{ height: '100%' }} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <ReactToPrint trigger={() => <Button>{t('Print')}</Button>} content={() => componentRef.current} />
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={componentRef} images={images} />
        </div>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
      </DialogActions>
    </>
  );
};

export default ViewImages;
