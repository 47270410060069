import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all mains
export const getAllAdmin = createAsyncThunk('admin/getAllAdmin', async (main, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllAdmin?main_id=${main}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create admin
export const createAdmin = createAsyncThunk('admin/createAdmin', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createAdmin`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Update admin
export const updateAdmin = createAsyncThunk('admin/updateAdmin', async ({ id, data }, thunkAPI) => {
  // console.log('🚀 ~ file: adminAction.js ~ line 31 ~ updateAdmin ~ formData', data);
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData(`/api/admin/updateAdmin/${id}`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete admin
export const deleteAdmin = createAsyncThunk('admin/deleteAdmin', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteAdmin/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
