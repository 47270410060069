import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all sub
export const getAllFileReceived = createAsyncThunk('filesReceived/getAllFileReceived', async (main, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllFileReceived`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create sub
export const createFileReceived = createAsyncThunk('filesReceived/createFileReceived', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createFileReceived`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Update sub
export const updateFileReceived = createAsyncThunk(
  'filesReceived/updateFileReceived',
  async ({ id, data }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await putData(`/api/admin/updateFileReceived/${id}`, data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete sub
export const deleteFileReceived = createAsyncThunk('filesReceived/deleteFileReceived', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteFileReceived/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
