import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';

// get all mains
export const getAllMain = createAsyncThunk('main/getAllMain', async (limit, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllMains`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create main
export const createMain = createAsyncThunk('main/createMain', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createMain`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete main
export const deleteMain = createAsyncThunk('main/deleteMain', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteMain/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
