import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData, postDataWithImage } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
// import { putDataWithImage } from '../../../api/putData';

// get all export
export const getAllExport = createAsyncThunk('export/getAllExport', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllExport${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// get images by export id
export const getImagesExport = createAsyncThunk('export/getImagesExport', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getImagesExport/${id}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// get Received by export main id
export const getReceivedByExport = createAsyncThunk('export/getReceivedByExport', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getReceivedByExport`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get all Received by export main id
export const getAllReceivedByExport = createAsyncThunk('export/getAllReceivedByExport', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllReceivedByExport${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create export
export const createExport = createAsyncThunk('export/createExport', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postDataWithImage(`/api/admin/createExport`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// Update export
export const updateExport = createAsyncThunk('export/updateExport', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postDataWithImage(`/api/admin/updateExport`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Show export
export const showExport = createAsyncThunk('export/showExport', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/showExport/${id}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//   exportMainAddDateAndNumber
export const exportMainAddDateAndNumber = createAsyncThunk(
  'export/exportMainAddDateAndNumber',
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await postData(`/api/admin/exportMainAddDateAndNumber`, data);
      // console.log('🚀 ~ file: exportAction.js ~ line 56 ~ res', res);
      return res;
    } catch (error) {
      // console.log('🚀 ~ file: exportAction.js ~ line 58 ~ error', error);
      return rejectWithValue(error.response.data);
    }
  }
);

//  exportMainAddDateAndNumber
export const exportMainEditDateAndNumber = createAsyncThunk(
  'export/exportMainEditDateAndNumber',
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await postData(`/api/admin/exportMainEditDateAndNumber`, data);
      // console.log('🚀 ~ file: exportAction.js ~ line 56 ~ res', res);
      return res;
    } catch (error) {
      // console.log('🚀 ~ file: exportAction.js ~ line 58 ~ error', error);
      return rejectWithValue(error.response.data);
    }
  }
);

//  exportMainEditStatus
export const exportMainEditStatus = createAsyncThunk('export/exportMainEditStatus', async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/exportMainEditStatus`, data);
    // console.log('🚀 ~ file: exportAction.js ~ line 56 ~ res', res);
    return res;
  } catch (error) {
    // console.log('🚀 ~ file: exportAction.js ~ line 58 ~ error', error);
    return rejectWithValue(error.response.data);
  }
});

// Delete export
export const deleteSub = createAsyncThunk('export/deleteSub', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteSub/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
