import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../components/Loadable';

// layout admin super
const DashboardLayoutSuper = Loadable(lazy(() => import('../layouts/dashboard/super-admin')));
// layout admin
const DashboardLayout = Loadable(lazy(() => import('../layouts/dashboard/admin')));
// layout admin guest
const GuestLayout = Loadable(lazy(() => import('../layouts/guest/admin')));
// super admin
const DashboardSuperAdminPage = Loadable(lazy(() => import('../pages/admin/DashboardSuperAdminPage')));
const AdminsManagementPage = Loadable(lazy(() => import('../pages/admin/AdminsManagementPage')));
const UserManagementPage = Loadable(lazy(() => import('../pages/admin/UserManagementPage')));
const MainAdministrationPage = Loadable(lazy(() => import('../pages/admin/MainAdministrationPage')));
// admin pages
const DashboardAdminPage = Loadable(lazy(() => import('../pages/admin/DashboardAdminPage')));
const ExportManagementPage = Loadable(lazy(() => import('../pages/admin/ExportManagementPage')));
const ExportEditPage = Loadable(lazy(() => import('../pages/admin/ExportEditPage')));
const ExportReportPage = Loadable(lazy(() => import('../pages/admin/ExportReportPage')));
const InternalReceivingManagementPage = Loadable(lazy(() => import('../pages/admin/InternalReceivingManagementPage')));
const InternalReceivingReportPage = Loadable(lazy(() => import('../pages/admin/InternalReceivingReportPage')));
const ReceivedManagementPage = Loadable(lazy(() => import('../pages/admin/ReceivedManagementPage')));
const ReceivedReportPage = Loadable(lazy(() => import('../pages/admin/ReceivedReportPage')));
const SubAdministrationPage = Loadable(lazy(() => import('../pages/admin/SubAdministrationPage')));
const FilesExportPage = Loadable(lazy(() => import('../pages/admin/FilesExportPage')));
const FilesReceivedPage = Loadable(lazy(() => import('../pages/admin/FilesReceivedPage')));
// authAdminAction
const LoginPage = Loadable(lazy(() => import('../pages/auth/admin/LoginPage')));

// const DashboardAppPage = Loadable(lazy(() => import('../pages/DashboardAppPage')));
// ==============================|| ADMIN ||============================== //

export const SuperAdminRoutes = {
  path: 'super-admin',
  element: <DashboardLayoutSuper name="super" path="admin" />,
  children: [
    { element: <Navigate to="dashboard" />, index: true },
    { path: 'dashboard', element: <DashboardSuperAdminPage /> },
    { path: 'admins-management', element: <AdminsManagementPage /> },
    { path: 'users-management', element: <UserManagementPage /> },
    { path: 'main-administrations', element: <MainAdministrationPage /> },
  ],
};

export const AdminRoutes = {
  path: 'admin',
  element: <DashboardLayout name="admin" path="admin" />,
  children: [
    { element: <Navigate to="dashboard" />, index: true },
    { path: 'dashboard', element: <DashboardAdminPage /> },
    { path: 'export-management', element: <ExportManagementPage /> },
    { path: 'export-edit', element: <ExportEditPage /> },
    { path: 'export-report', element: <ExportReportPage /> },
    { path: 'internal-receiving-management', element: <InternalReceivingManagementPage /> },
    { path: 'internal-receiving-report', element: <InternalReceivingReportPage /> },
    { path: 'received-management', element: <ReceivedManagementPage /> },
    { path: 'received-report', element: <ReceivedReportPage /> },
    { path: 'sub-administrations', element: <SubAdministrationPage /> },
    { path: 'file-export', element: <FilesExportPage /> },
    { path: 'file-received', element: <FilesReceivedPage /> },
  ],
};

// export const AdminRoutes = {
//   path: 'admin/',
//   element: <DashboardLayout />,
//   children: [
//     { element: <Navigate to="dashboard" />, index: true },
//     { path: 'dashboard', element: <DashboardAppPage /> },
//     { path: 'export-management', element: <ExportManagementPage /> },
//     { path: 'export-report', element: <ExportReportPage /> },
//     { path: 'received-management', element: <ReceivedManagementPage /> },
//     { path: 'received-report', element: <ReceivedReportPage /> },
//     { path: 'sub-administrations', element: <SubAdministrationPage /> },
//     { path: 'file-export', element: <FilesExportPage /> },
//     { path: 'file-received', element: <FilesReceivedPage /> },
//   ],
// };

export const AuthenticationAdminRoutes = {
  path: 'admin',
  element: <GuestLayout />,
  children: [{ path: 'login', element: <LoginPage /> }],
};
