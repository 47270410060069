import { createAsyncThunk } from '@reduxjs/toolkit';
import { postDataWithImage } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all received
export const getAllReceived = createAsyncThunk('received/getAllReceived', async (url, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllReceived${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get all image received by id
export const getImagesReceived = createAsyncThunk('received/getImagesReceived', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getImagesReceived/${id}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create received
export const createReceived = createAsyncThunk('received/createReceived', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postDataWithImage(`/api/admin/createReceived`, formData);
    return res;
  } catch (error) {
    // console.log('🚀 ~ file: receivedAction.js ~ line 36 ~ createReceived ~ error', error);
    return rejectWithValue(error.response.data);
  }
});

// Update received
export const updateSub = createAsyncThunk('received/updateSub', async ({ id, data }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData(`/api/admin/updateSub/${id}`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete received
export const deleteSub = createAsyncThunk('received/deleteSub', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteSub/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
