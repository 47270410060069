import { createSlice } from '@reduxjs/toolkit';
import { getLoggedUser, loginUser, logoutUser, updatePasswordUser } from '../../actions/user/authUserAction';

const initialState = {
  authUser: null,
  loading: false,
  error: null,
};

const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    clearAuthUser: (state) => {
      state.authUser = null;
    },
  },
  extraReducers: {
    // login user
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state) => {
      state.loading = false;
      //  state.authAdmin = action.payload;
    },
    [loginUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // logoutUser
    [logoutUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [logoutUser.fulfilled]: (state) => {
      state.loading = false;
      state.authUser = null;
    },
    [logoutUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // update passwordUser
    [updatePasswordUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updatePasswordUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePasswordUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get LoggedUser
    [getLoggedUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLoggedUser.fulfilled]: (state) => {
      state.loading = false;
      //  state.authAdmin = action.payload;
    },
    [getLoggedUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setAuthUser, clearAuthUser } = authUserSlice.actions;

export default authUserSlice.reducer;
