import { createSlice } from '@reduxjs/toolkit';
import { createMain, deleteMain, getAllMain } from '../../actions/admin/mainAction';

const initialState = {
  mains: [],
  loading: false,
  error: null,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  extraReducers: {
    // get All mains
    [getAllMain.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.mains = action.payload;
    },
    [getAllMain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create main
    [createMain.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.mains.push(action.payload);
    },
    [createMain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // delete main
    [deleteMain.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMain.fulfilled]: (state, action) => {
      state.loading = false;
      state.mains = state.mains.filter((el) => el.id !== action.payload);
    },
    [deleteMain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default mainSlice.reducer;
