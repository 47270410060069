import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  severity: 'success',
  vertical: 'top',
  horizontal: 'center',
};

const alertSlice = createSlice({
  name: 'alert ',
  initialState,
  reducers: {
    openAlert: (state, action) => {
      state.isOpen = true;
      state.severity = action.payload || 'success';
    },
    closeAlert: (state) => {
      state.isOpen = false;
      state.severity = 'success';
    },
  },
});
export const { openAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;
