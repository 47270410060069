import { createSlice } from '@reduxjs/toolkit';
import { getAllExport, getAllReceivedByExport, getAllReceived } from '../../actions/user/reportAction';

const initialState = {
  exports: [],
  allReceivedByExports: [],
  receivers: [],
  loading: false,
  error: null,
};

const reportSlice = createSlice({
  name: 'export',
  initialState,
  extraReducers: {
    // get All exports
    [getAllExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.exports = action.payload;
    },
    [getAllExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get All received by export by main id
    [getAllReceivedByExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllReceivedByExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.allReceivedByExports = action.payload;
    },
    [getAllReceivedByExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // get All received
    [getAllReceived.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllReceived.fulfilled]: (state, action) => {
      state.loading = false;
      state.receivers = action.payload;
    },
    [getAllReceived.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default reportSlice.reducer;
