import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// localStorage
import { getDataLocalStorage, removeDataLocalStorage } from '../../utils/helpers/localStorageHelper';
// slices
import { setAuthAdmin } from '../../redux/slices/admin/authAdminSlice';

const useAuthAdmin = () => {
  // state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { authAdmin } = useSelector((state) => state.authAdmin);
  // hooks
  const navigate = useNavigate();
  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    const userData = getDataLocalStorage('admin');
    if (userData) {
      if (!authAdmin) {
        dispatch(setAuthAdmin(userData));
      }
      if (userData.role === 1) {
        navigate('/super-admin');
      }
      setIsAuthenticated(true);
    } else {
      navigate('/admin/login');
    }
  }, [navigate, dispatch, authAdmin]);

  return {
    isAuthenticated,
  };
};

export default useAuthAdmin;

// super admin
export const useAuthSuperAdmin = () => {
  // state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { authAdmin } = useSelector((state) => state.authAdmin);
  // hooks
  const navigate = useNavigate();
  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    const userData = getDataLocalStorage('admin');
    if (userData) {
      if (!authAdmin) {
        dispatch(setAuthAdmin(userData));
      }
      if (userData.role === 0) {
        navigate('/admin');
      }
      setIsAuthenticated(true);
    } else {
      navigate('/admin/login');
    }
  }, [navigate, dispatch, authAdmin]);

  return {
    isAuthenticated,
  };
};

export const logOut = () => {
  if (typeof window !== 'undefined') {
    if (getDataLocalStorage('admin')) {
      removeDataLocalStorage('admin');
      window.location = '/admin/login';
    }
    if (getDataLocalStorage('user')) {
      removeDataLocalStorage('user');
      window.location = 'login';
    }
    if (getDataLocalStorage('super')) {
      removeDataLocalStorage('super');
      window.location = '/admin/login';
    }
  }
};

export const useGuestAdmin = () => {
  // state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // hooks
  const navigate = useNavigate();
  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    const userData = getDataLocalStorage('admin');
    if (userData) {
      if (userData.role === 1) {
        navigate('/super-admin');
      } else if (userData.role === 0) {
        navigate('/admin');
      }
      setIsAuthenticated(true);
    }
  }, [navigate, dispatch]);

  return {
    isAuthenticated,
  };
};
