import PropTypes from 'prop-types';
// materialTable react table
import MaterialReactTable from 'material-react-table';
// i18next
import { useTranslation } from 'react-i18next';

const MaterialReact = ({
  columns,
  data,
  state,
  enableRowNumbers,
  renderRowActions,
  positionActionsColumn = 'first',
}) => {
  // i18next
  const { t } = useTranslation();

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      state={state}
      enableRowNumbers={enableRowNumbers}
      rowNumberMode="static"
      enableRowActions
      enableGrouping
      enableColumnFilterModes
      renderRowActions={renderRowActions}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          muiTableHeadCellProps: {
            align: 'center',
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
          size: 150,
        },
      }}
      positionActionsColumn={positionActionsColumn}
      localization={{
        actions: t('Actions'),
        and: 'و',
        cancel: t('Cancel'),
        changeFilterMode: 'تغيير وضع التصفية',
        changeSearchMode: 'Change search mode',
        clearFilter: 'إلغاء البحث',
        clearSearch: 'إلغاء البحث',
        clearSort: 'إلغاء الفرز',
        clickToCopy: 'Click to copy',
        columnActions: 'اجراءات العمود',
        copiedToClipboard: 'Copied to clipboard',
        dropToGroupBy: 'أفلت للمجموعة حسب {column}',
        edit: 'Edit',
        expand: 'يوسع',
        expandAll: 'Expand all',
        filterArrIncludes: 'Includes',
        filterArrIncludesAll: 'Includes all',
        filterArrIncludesSome: 'Includes',
        filterBetween: 'ما بين',
        filterBetweenInclusive: 'بين الشمول',
        filterByColumn: 'البحث حسب {column}',
        filterContains: 'يتضمن',
        filterEmpty: 'فارغة',
        filterEndsWith: 'ينتهي بـ',
        filterEquals: 'يساوي',
        filterEqualsString: 'يساوي',
        filterFuzzy: 'العادي',
        filterGreaterThan: 'أكبر من',
        filterGreaterThanOrEqualTo: 'أكبر من أو يساوي',
        filterInNumberRange: 'ما بين',
        filterIncludesString: 'يتضمن',
        filterIncludesStringSensitive: 'Contains',
        filterLessThan: 'أقل من',
        filterLessThanOrEqualTo: 'اقل او يساوي',
        filterMode: 'وضع التصفية: {filterType}',
        filterNotEmpty: 'ليس فارغًا',
        filterNotEquals: 'لا يساوي',
        filterStartsWith: 'يبدأ بـ',
        filterWeakEquals: 'يساوي',
        filteringByColumn: 'التصفية حسب {column} - {filterType} {filterValue}',
        goToFirstPage: 'Go to first page',
        goToLastPage: 'Go to last page',
        goToNextPage: 'Go to next page',
        goToPreviousPage: 'Go to previous page',
        grab: 'Grab',
        groupByColumn: 'مجموعة من {column}',
        groupedBy: 'مجمعة حسب ',
        hideAll: 'إخفاء الكل',
        hideColumn: 'إخفاء العمود {column}',
        max: 'Max',
        min: 'Min',
        move: 'تحريك',
        noRecordsToDisplay: 'لا توجد بيانات',
        noResultsFound: 'لا توجد بيانات',
        of: 'من',
        or: 'او',
        pinToLeft: 'Pin to left',
        pinToRight: 'Pin to right',
        resetColumnSize: 'Reset column size',
        resetOrder: 'Reset order',
        rowActions: 'Row Actions',
        rowNumber: '#',
        rowNumbers: 'Row Numbers',
        rowsPerPage: 'عدد الصفوف في الصفحة',
        save: 'حفظ',
        search: 'البحث',
        selectedCountOfRowCountRowsSelected: '{selectedCount} of {rowCount} row(s) selected',
        select: 'اختيار',
        showAll: 'عرض الكل',
        showAllColumns: 'إظهار جميع الاعمدة',
        showHideColumns: 'إظهار/إخفاء الاعمدة',
        showHideFilters: 'إظهار/إخفاء البحث',
        showHideSearch: 'إظهار/إخفاء البحث',
        sortByColumnAsc: 'الفرز تصاعديا حسب {column}',
        sortByColumnDesc: 'الفرز تنازليا حسب {column}',
        sortedByColumnAsc: 'مرتبة حسب {column} تصاعديًا',
        sortedByColumnDesc: 'مرتبة حسب {column} تنازليًا',
        thenBy: ', ثم بواسطة ',
        toggleDensity: 'تبديل الكثافة',
        toggleFullScreen: 'شاشة كاملة',
        toggleSelectAll: 'Toggle select all',
        toggleSelectRow: 'Toggle select row',
        toggleVisibility: 'Toggle visibility',
        ungroupByColumn: 'فك التجميع حسب {column}',
        unpin: 'Unpin',
        unpinAll: 'Unpin all',
        unsorted: 'غير مصنف',
        // ... and many more - see link below for full list of translation keys
      }}
    />
  );
};

MaterialReact.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  state: PropTypes.object,
  renderRowActions: PropTypes.func,
  positionActionsColumn: PropTypes.string,
  enableRowNumbers: PropTypes.bool,
};

export default MaterialReact;
