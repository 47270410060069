import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// mui
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdatePasswordSchema } from '../../../../utils/validation';
// components
import { ControlledTextField } from '../../../../components/form-Input';
// actions
import { closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { updatePasswordAdmin } from '../../../../redux/actions/admin/authAdminAction';

const UpdatePassword = () => {
  // state
  const { loading } = useSelector((state) => state.authAdmin);
  // console.log('🚀 ~ file: AdminsManagementEdit.js ~ line 26 ~ AdminsManagementEdit ~ item', item);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  });

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // functions
  const onSubmit = async (data) => {
    // console.log('🚀 ~ file: InternalReceivingEdit.js ~ line 50 ~ onSubmit ~ data', data);
    await dispatch(
      updatePasswordAdmin({
        current_password: data.current_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      })
    )
      .unwrap()
      .then(async () => {
        dispatch(openAlert());
        dispatch(closeModal());
        reset();
      })
      .catch((errors) => {
        if (errors.errors) {
          Object.keys(errors.errors).forEach((error) => {
            setError(error, {
              type: error,
              message: errors.errors[error][0],
            });
          });
        } else {
          dispatch(closeModal());
          dispatch(openAlert('error'));
        }
      });
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              type={'password'}
              name="current_password"
              label={t('Current Password')}
              errors={errors}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              type={'password'}
              name="password"
              label={t('New Password')}
              errors={errors}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              type={'password'}
              name="password_confirmation"
              label={t('Confirm Password')}
              errors={errors}
              defaultValue={''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
        <LoadingButton
          color="success"
          loading={loading}
          loadingIndicator={t('Loading…')}
          onClick={handleSubmit(onSubmit)}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UpdatePassword;
