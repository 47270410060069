import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
// admin
import { AdminsManagementCreate, AdminsManagementEdit } from '../../sections/@dashboard/admin/adminsManagement';
import { UserManagementCreate, UserManagementEdit } from '../../sections/@dashboard/admin/usersManagement';
import { MainAdministrationCreate } from '../../sections/@dashboard/admin/mainAdministration';
import { SubAdministrationCreate, SubAdministrationEdit } from '../../sections/@dashboard/admin/subAdministration';
import { FileExportCreate, FileExportEdit } from '../../sections/@dashboard/admin/fileExport';
import { FileReceivedCreate, FileReceivedEdit } from '../../sections/@dashboard/admin/fileReceived';
import { InternalReceivingEdit,InternalReceivingAdd } from '../../sections/@dashboard/admin/internalReceiving';
import { UpdatePasswordAdmin } from '../../sections/@dashboard/admin/authAdmin';
import { ViewImages } from '../material-table';
// user
import { UpdatePasswordUser } from '../../sections/@dashboard/user/authUser';

const FormDialog = () => {
  // state
  const { isOpen, componentName, fullWidth, item } = useSelector((state) => state.modal);
  // i18next
  const { t } = useTranslation();

  const componentsLookUp = {
    MainAdministrationCreate,
    AdminsManagementCreate,
    AdminsManagementEdit,
    UserManagementCreate,
    UserManagementEdit,
    SubAdministrationCreate,
    SubAdministrationEdit,
    FileExportCreate,
    FileExportEdit,
    FileReceivedCreate,
    FileReceivedEdit,
    InternalReceivingEdit,
    InternalReceivingAdd,
    UpdatePasswordAdmin,
    UpdatePasswordUser,
    ViewImages,
  };
  let renderComponent;
  if (componentName) {
    const SelectedComponent = componentsLookUp[componentName];
    if (SelectedComponent) {
      renderComponent = <SelectedComponent />;
    }
  }

  return (
    <div>
      <Dialog fullWidth={fullWidth} open={isOpen}>
        {componentName !== 'ViewImages' ? <DialogTitle>{item ? t('Edit') : t('Add')}</DialogTitle> : null}
        {renderComponent}
      </Dialog>
    </div>
  );
};
export default FormDialog;
