import { createAsyncThunk } from '@reduxjs/toolkit';
import { putData } from '../../../api/putData';
import getData from '../../../api/getData';
import { postData } from '../../../api/postData';
// import deleteData from '../../../api/deleteData';

// login admin
export const loginAdmin = createAsyncThunk('authAdmin/loginAdmin', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData('api/admin/login', formData);
    // console.log('🚀 ~ file: authAdminAction.js ~ line 25 ~ loginAdmin ~ res', res);
    return res;
  } catch (error) {
    // console.log('🚀 ~ file: authAdminAction.js ~ line 23 ~ loginAdmin ~ error.response.data', error.response.data);
    return rejectWithValue(error.response.data);
  }
});
// export const loginAdmin = createAsyncThunk('authAdmin/loginAdmin', async (formData, thunkAPI) => {
//   const { rejectWithValue } = thunkAPI;
//   try {
//     const res = await postData('api/admin/access-tokens', formData);
//     console.log('🚀 ~ file: authAdminAction.js ~ line 25 ~ loginAdmin ~ res', res);
//     return res;
//   } catch (error) {
//     console.log('🚀 ~ file: authAdminAction.js ~ line 23 ~ loginAdmin ~ error.response.data', error.response.data);
//     return rejectWithValue(error.response.data);
//   }
// });

// logout admin
export const logoutAdmin = createAsyncThunk('authAdmin/logoutAdmin', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData('api/admin/logout', formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// logout admin
// export const logoutAdmin = createAsyncThunk('authAdmin/logoutAdmin', async (formData, thunkAPI) => {
//   const { rejectWithValue } = thunkAPI;
//   try {
//     const res = await deleteData('api/admin/access-tokens', formData);
//     return res;
//   } catch (error) {
//     return rejectWithValue(error.response.data);
//   }
// });

// update password admin
export const updatePasswordAdmin = createAsyncThunk('authAdmin/updatePasswordAdmin', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData('api/admin/user/password', formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Logged admin
export const getLoggedAdmin = createAsyncThunk('authAdmin/getLoggedAdmin', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData('/api/admin/getMe');
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
