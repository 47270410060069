import { createAsyncThunk } from '@reduxjs/toolkit';
import getData from '../../../api/getData';

// get  notifications user
export const getNotifications = createAsyncThunk('userNotifications/getNotifications', async (url = '', thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/userNotifications${url}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// markAsReadAll notifications  user
export const markAsReadAll = createAsyncThunk('userNotifications/markAsReadAll', async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/user/markAsReadAll`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
