import { createSlice } from '@reduxjs/toolkit';
import {
  createExport,
  updateExport,
  exportMainAddDateAndNumber,
  getAllExport,
  getAllReceivedByExport,
  getReceivedByExport,
  showExport,
  exportMainEditDateAndNumber,
  exportMainEditStatus,
} from '../../actions/admin/exportAction';

const initialState = {
  exports: [],
  export: [],
  exportId: null,
  receivedByExports: [],
  AllReceivedByExports: [],
  loading: false,
  error: null,
};

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    pushExportId: (state, action) => {
      state.exportId = null;
      state.exportId = action.payload;
    },
  },
  extraReducers: {
    // get All exports
    [getAllExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.exports = action.payload;
    },
    [getAllExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get All received by export by main id
    [getReceivedByExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getReceivedByExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.receivedByExports = action.payload;
    },
    [getReceivedByExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get All received by export by main id
    [getAllReceivedByExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllReceivedByExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.AllReceivedByExports = action.payload;
    },
    [getAllReceivedByExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // create export
    [createExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.export = action.payload;
    },
    [createExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // update export
    [updateExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.export = action.payload;
    },
    [updateExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Show export
    [showExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [showExport.fulfilled]: (state, action) => {
      state.loading = false;
      state.export = action.payload;
    },
    [showExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // export Main Add Date And Number
    [exportMainAddDateAndNumber.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [exportMainAddDateAndNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.receivedByExports.data = state.receivedByExports.data.filter((el) => el.id !== action.payload);
    },
    [exportMainAddDateAndNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // export Main edit Date And Number
    [exportMainEditDateAndNumber.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [exportMainEditDateAndNumber.fulfilled]: (state, action) => {
      state.loading = false;
      state.AllReceivedByExports.data = state.AllReceivedByExports.data.map((el) =>
        el.id === action.payload.export_id
          ? {
              ...el,
              export_received_at: action.payload.export_received_at,
              received_serial_number: action.payload.received_serial_number,
            }
          : el
      );
    },
    [exportMainEditDateAndNumber.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // export Main edit Status
    [exportMainEditStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [exportMainEditStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.AllReceivedByExports.data = state.AllReceivedByExports.data.map((el) =>
        el.id === action.payload.export_id
          ? {
              ...el,
              status: action.payload.status,
            }
          : el
      );
    },
    [exportMainEditStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { pushExportId } = exportSlice.actions;
export default exportSlice.reducer;
