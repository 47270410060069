import { createAsyncThunk } from '@reduxjs/toolkit';
import { postData } from '../../../api/postData';
import deleteData from '../../../api/deleteData';
import getData from '../../../api/getData';
import { putData } from '../../../api/putData';

// get all user
export const getAllUser = createAsyncThunk('user/getAllUser', async (main, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await getData(`/api/admin/getAllUser?main_id=${main}`);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create user
export const createUser = createAsyncThunk('user/createUser', async (formData, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await postData(`/api/admin/createUser`, formData);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Update user
export const updateUser = createAsyncThunk('user/updateUser', async ({ id, data }, thunkAPI) => {
  // console.log('🚀 ~ file: adminAction.js ~ line 31 ~ updateAdmin ~ formData', data);
  const { rejectWithValue } = thunkAPI;
  try {
    const res = await putData(`/api/admin/updateUser/${id}`, data);
    return res;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Delete user
export const deleteUser = createAsyncThunk('user/deleteUser', async (id, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    await deleteData(`/api/admin/deleteUser/${id}`);
    return id;
  } catch (error) {
    // console.log('🚀 ~ file: mainAction.js ~ line 24 ~ deleteMain ~ error', error);
    return rejectWithValue(error.message);
  }
});
