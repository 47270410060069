import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// mui
import Box from '@mui/material/Box';
import { sentenceCase } from 'change-case';
// components
import AlertDialogDelete from '../../../../components/utils/AlertDialogDelete';
import { MaterialReact, Actions } from '../../../../components/material-table';
import Label from '../../../../components/label';
// actions
import { closeModalDelete, openModal, openModalDelete } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { deleteUser } from '../../../../redux/actions/admin/userAction';
// utils
import { fToNow } from '../../../../utils/formatTime';

const UserManagementList = () => {
  // state
  const { users, loading } = useSelector((state) => state.users);
  // console.log('🚀 ~ file: AdminsManagementList.js ~ line 26 ~ AdminsManagementList ~ admins', users);
  const [id, setId] = useState(0);

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // functions
  const deleteMa = useCallback(
    (id) => {
      setId(id);
      dispatch(openModalDelete());
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        size: 200,
      },
      {
        accessorKey: 'username',
        header: t('User Name'),
      },
      {
        accessorKey: 'email',
        header: t('Email'),
      },
      {
        accessorKey: 'main_name',
        header: t('Name Main Administrations'),
      },
      {
        accessorKey: 'sub_name',
        header: t('Name Sub Administrations'),
      },
      {
        accessorKey: 'status',
        header: t('Action Status'),
        Cell: (prop) => {
          const { cell } = prop;
          return (
            <Label color={(cell.getValue() === 'inactive' && 'error') || 'success'}>
              {sentenceCase(cell.getValue())}
            </Label>
          );
        },
      },
      {
        accessorKey: 'last_see',
        header: t('Last See'),
        Cell: ({ cell }) => fToNow(cell.getValue()),
      },
    ],
    [t]
  );

  // functions
  const handleDelete = useCallback(async () => {
    await dispatch(deleteUser(id))
      .unwrap()
      .then(() => {
        dispatch(openAlert());
        dispatch(closeModalDelete());
      })
      .catch(() => {
        dispatch(closeModalDelete());
        dispatch(openAlert('error'));
      });
  }, [dispatch, id]);

  return (
    <>
      <Box sx={{ height: 500, width: '100%', direction: 'ltr' }}>
        <MaterialReact
          columns={columns}
          data={users ?? []}
          state={{ isLoading: loading }}
          enableRowNumbers
          positionActionsColumn="last"
          renderRowActions={(row) => (
            <Actions
              onClickEdit={() => dispatch(openModal({ name: 'UserManagementEdit', item: row.row.original }))}
              onClickDelete={() => deleteMa(row.row.original.id)}
            />
          )}
        />
      </Box>
      <AlertDialogDelete handleDelete={handleDelete} />
    </>
  );
};

export default UserManagementList;
