import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// mui
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { InternalReceivingUpdateSchema } from '../../../../utils/validation';
// components
import { ControlledTextField, ControlledDatePicker } from '../../../../components/form-Input';
// actions
import { closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { exportMainAddDateAndNumber } from '../../../../redux/actions/admin/exportAction';
import { getNotifications } from '../../../../redux/actions/admin/notificationAction';
// utils
import { fDate } from '../../../../utils/formatTime';

const InternalReceivingAdd = () => {
  // state
  const { item } = useSelector((state) => state.modal);
  const { loading } = useSelector((state) => state.exports);
  // console.log('🚀 ~ file: AdminsManagementEdit.js ~ line 26 ~ AdminsManagementEdit ~ item', item);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(InternalReceivingUpdateSchema),
    defaultValues: {
      received_serial_number: '',
      export_received_at: fDate(Date.now()),
    },
  });

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // functions
  const onSubmit = async (data) => {
    // console.log('🚀 ~ file: InternalReceivingEdit.js ~ line 50 ~ onSubmit ~ data', data);
    await dispatch(
      exportMainAddDateAndNumber({
        received_serial_number: data.received_serial_number,
        export_received_at: fDate(data.export_received_at),
        export_id: item.pivot.export_id,
        main_id: item.pivot.main_id,
      })
    )
      .unwrap()
      .then(async () => {
        dispatch(openAlert());
        dispatch(closeModal());
        dispatch(getNotifications());
        reset();
      })
      .catch((errors) => {
        if (errors.errors) {
          Object.keys(errors.errors).forEach((error) => {
            setError(error, {
              type: error,
              message: errors.errors[error][0],
            });
          });
        } else {
          dispatch(closeModal());
          dispatch(openAlert('error'));
        }
      });
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={12} md={6}>
            <ControlledTextField
              control={control}
              name="received_serial_number"
              type={'number'}
              label={t('serial_number')}
              errors={errors}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledDatePicker
              control={control}
              name="export_received_at"
              label={t('Date')}
              errors={errors}
              defaultValue={''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
        <LoadingButton
          color="success"
          loading={loading}
          loadingIndicator={t('Loading…')}
          onClick={handleSubmit(onSubmit)}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default InternalReceivingAdd;
