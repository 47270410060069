import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { closeModalDelete } from '../../redux/slices/modal';

const AlertDialogDelete = ({ handleDelete }) => {
  // state
  const { isOpenDelete, fullWidth } = useSelector((state) => state.modal);
  // dispatch
  const dispatch = useDispatch();
  // i18next
  const { t } = useTranslation();
  // functions
  const handleClose = () => {
    dispatch(closeModalDelete());
  };
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        open={isOpenDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Are you sure you delete this?')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogDelete;

AlertDialogDelete.propTypes = {
  handleDelete: PropTypes.func,
};
