import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  image: null,
  file: null,
};

const cropSlice = createSlice({
  name: 'crop ',
  initialState,
  reducers: {
    openCrop: (state, action) => {
      state.isOpen = true;
      state.image = action.payload;
    },
    closeCrop: (state) => {
      state.isOpen = false;
      state.image = null;
    },
    indexPhoto: (state, action) => {
      state.index = action.payload;
    },
  },
});
export const { openCrop, closeCrop, indexPhoto } = cropSlice.actions;
export default cropSlice.reducer;
