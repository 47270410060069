import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// mui
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
// components
import Iconify from '../iconify';

const Actions = ({ onClick, onClickEdit, onClickDelete }) => {
  // i18next
  const { t } = useTranslation();
  return (
    <Box>
      {onClick && (
        <Tooltip title={t('View Message')}>
          <IconButton onClick={onClick}>
            <Iconify icon={'carbon:view-filled'} />
          </IconButton>
        </Tooltip>
      )}
      {onClickEdit && (
        <Tooltip sx={{ ml: 2 }} title={t('Edit')}>
          <IconButton onClick={onClickEdit}>
            <Iconify icon={'ci:edit'} />
          </IconButton>
        </Tooltip>
      )}
      {onClickDelete && (
        <Tooltip sx={{ ml: 2 }} title={t('Delete')}>
          <IconButton onClick={onClickDelete}>
            <Iconify icon={'ic:baseline-delete'} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
Actions.propTypes = {
  onClick: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default Actions;
