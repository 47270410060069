import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  index: 0,
  images: [],
};

const lightboxSlice = createSlice({
  name: 'lightbox ',
  initialState,
  reducers: {
    openLightbox: (state, action) => {
      state.isOpen = true;
      state.index = action.payload.index;
      state.images = action.payload.images;
    },
    closeLightbox: (state) => {
      state.isOpen = false;
      state.index = 0;
      state.images = [];
    },
    indexPhoto: (state, action) => {
      state.index = action.payload;
    },
  },
});
export const { openLightbox, closeLightbox, indexPhoto } = lightboxSlice.actions;
export default lightboxSlice.reducer;
