// mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// routes
import Router from './routes';
// import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { FormDialog, CustomizedSnackbars } from './components/utils';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider>
        {/* <FullScreenDialog /> */}
        <CustomizedSnackbars />
        <FormDialog />
        <ScrollToTop />
        <StyledChart />
        <Router />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
