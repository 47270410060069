import PropTypes from 'prop-types';
// mui
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
// react hook form
import { Controller } from 'react-hook-form';

ControlledDatePicker.propTypes = {
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
};

function ControlledDatePicker({ label, control, defaultValue, name, errors }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <DesktopDatePicker
          inputFormat="yyyy/MM/dd"
          onChange={onChange}
          value={value}
          label={label}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              error={!!errors[name]}
              helperText={errors[name] && errors[name].message}
            />
          )}
        />
      )}
    />
  );
}

export default ControlledDatePicker;
