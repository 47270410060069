import axios from 'axios';
import { logOut } from '../hooks/auth';

// export const baseDomain = 'http://localhost:8000';
export const baseDomain = 'https://bma.nu.edu.ly';
// export const baseDomain = 'https://b.csmartn.com';
// export const baseDomain = 'http://127.0.0.1:8000';
// export const baseDomain = 'https://archive-management-laravel.herokuapp.com';

const API_SERVER = `${baseDomain}`;

const baseURL = axios.create({
  baseURL: API_SERVER,
  withCredentials: true,
});

baseURL.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
      // console.log('🚀 ~ file: Api.js ~ line 17 ~ error', error);
      logOut();
    }
    return Promise.reject(error);
  }
);

export default baseURL;
