import { createSlice } from '@reduxjs/toolkit';
import { getChart, getStatistics } from '../../actions/user/statisticsAction';

const initialState = {
  statistics: [],
  charts: [],
  loading: false,
  error: null,
};

const statisticsSlice = createSlice({
  name: 'statistic',
  initialState,
  extraReducers: {
    // get statistics
    [getStatistics.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.loading = false;
      state.statistics = action.payload;
    },
    [getStatistics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // get exportChart
    [getChart.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getChart.fulfilled]: (state, action) => {
      state.loading = false;
      state.charts = action.payload;
    },
    [getChart.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default statisticsSlice.reducer;
