import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ComponentToPrint = forwardRef((props, ref) => (
  <div ref={ref} style={{ backgroundPosition: 'center center', backgroundSize: 'cover' }}>
    {props.images.map((item, index) => (
      <div key={index}>
        <img src={item} srcSet={item} alt={'images'} loading="lazy" />
      </div>
    ))}
  </div>
));

export default ComponentToPrint;

ComponentToPrint.propTypes = {
  images: PropTypes.array,
};
