// import { getDataLocalStorage } from '../utils/helpers/localStorageHelper';
import baseURL from './Api';

const getData = async (url, params) => {
  const res = await baseURL.get(url, params);
  return res.data;
};

export default getData;

// const getData = async (url) => {
//   const config = {
//     headers: { Authorization: `Bearer ${getDataLocalStorage('token')}` },
//   };
//   const res = await baseURL.get(url, config);
//   return res.data;
// };

