import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { UserManagementCreateSchema } from '../../../../utils/validation';
// actions
import { getAllMain } from '../../../../redux/actions/admin/mainAction';
import { closeModal } from '../../../../redux/slices/modal';
import { openAlert } from '../../../../redux/slices/alert';
import { createUser } from '../../../../redux/actions/admin/userAction';
import { getSubByMainId } from '../../../../redux/actions/admin/subAction';

const UserManagementCreate = () => {
  // state
  const { mains } = useSelector((state) => state.mains);
  const { subByMainId } = useSelector((state) => state.subs);
  const { loading } = useSelector((state) => state.users);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    reset,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(UserManagementCreateSchema),
    defaultValues: {
      name: '',
      username: '',
      email: '',
      main_id: '',
      sub_id: '',
      password: '',
      password_confirmation: '',
    },
  });

  // dispatch
  const dispatch = useDispatch();

  // i18next
  const { t } = useTranslation();

  // watch
  const mainId = watch('main_id');

  // functions
  const onSubmit = async (data) => {
    await dispatch(createUser(data))
      .unwrap()
      .then(async () => {
        dispatch(openAlert());
        dispatch(closeModal());
        reset();
      })
      .catch((errors) => {
        if (errors.errors) {
          Object.keys(errors.errors).forEach((error) => {
            setError(error, {
              type: error,
              message: errors.errors[error][0],
            });
          });
        } else {
          dispatch(closeModal());
          dispatch(openAlert('error'));
        }
      });
  };

  // useEffect
  useEffect(() => {
    (async () => {
      await dispatch(getAllMain());
    })();
  }, [dispatch]);

  // useEffect
  useEffect(() => {
    (async () => {
      if (mainId) {
        await dispatch(getSubByMainId(mainId));
      } else {
        setValue('sub_id', ``);
      }
    })();
  }, [dispatch, mainId, setValue]);

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid xs={12} md={6}>
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'text'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  label={t('Name')}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Controller
              name={'username'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'text'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.username}
                  label={t('User Name')}
                  helperText={errors.username?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'text'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.email}
                  label={t('Email')}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Controller
              name={'main_id'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  select
                  label={t('Name Main Administrations')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.main_id}
                  helperText={errors.main_id?.message}
                >
                  <MenuItem value="">
                    <em>{t('None')}</em>
                  </MenuItem>
                  {mains && mains.length > 0
                    ? mains.map((main) => (
                        <MenuItem key={main.id} value={main.id}>
                          {main.name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              )}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <Controller
              name={'sub_id'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  select
                  label={t('Name Sub Administrations')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.sub_id}
                  helperText={errors.sub_id?.message}
                >
                  <MenuItem value="">
                    <em>{t('None')}</em>
                  </MenuItem>
                  {mainId && subByMainId && subByMainId.length > 0
                    ? subByMainId.map((sub) => (
                        <MenuItem key={sub.id} value={sub.id}>
                          {sub.name}
                        </MenuItem>
                      ))
                    : null}
                </TextField>
              )}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'password'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.password}
                  label={t('Password')}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Controller
              name={'password_confirmation'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  type={'password'}
                  onChange={onChange}
                  value={value}
                  error={!!errors.password_confirmation}
                  label={t('Confirm Password')}
                  helperText={errors.password_confirmation?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeModal())}>{t('Close')}</Button>
        <LoadingButton
          color="success"
          loading={loading}
          loadingIndicator={t('Loading…')}
          onClick={handleSubmit(onSubmit)}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UserManagementCreate;
