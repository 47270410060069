import PropTypes from 'prop-types';
// mui
import TextField from '@mui/material/TextField';
// react hook form
import { Controller } from 'react-hook-form';

ControlledTextField.propTypes = {
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.string,
  InputProps: PropTypes.object,
};

function ControlledTextField({
  label,
  control,
  defaultValue,
  name,
  errors,
  InputProps,
  type = 'text',
  size = 'small',
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          fullWidth
          size={size}
          type={type}
          onChange={onChange}
          value={value}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          InputProps={InputProps}
        />
      )}
    />
  );
}

export default ControlledTextField;
